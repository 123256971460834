<template>
  <b-card
    class="card-app-design rooms-container"
    title="Manage Livestock"
  >
    <b-row>
      <b-col sm="12">
        <div class="custom-search d-md-flex justify-content-between mb-3">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
          <b-button
            variant="outline-primary"
            size="md"
            @click="handleOpenRegisterModal()"
          >
            Register
          </b-button>
        </div>

        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'fullName'"
              class="text-nowrap"
            >
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="handleOpenViewProduceModal(props.row)">
                    <feather-icon
                      icon="EyeIcon"
                      class="mr-50"
                    />
                    <span>View</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="handleOpenEditProduceModal(props.row)">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="props.row.quantity > 0"
                    @click="handleOpenManageLivestockModal(props.row)"
                  >
                    <feather-icon
                      icon="SettingsIcon"
                      class="mr-50"
                    />
                    <span>Manage</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">

              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
        <b-modal
          v-model="openManageLivestockModal"
          title="Manage Livestock"
          size="sm"
          ok-title="Submit"
          @ok.prevent="validateManageLiveStockForm"
        >
          <validation-observer ref="manageLivestock">
            <b-form>
              <b-row>
                <b-col sm="12">
                  <div class="d-flex align-content-center justify-content-center">
                    <b-alert
                      variant="warning"
                      show
                      class="py-1 px-1"
                    >
                      <p>You're changing the status of {{ ' ' }}
                        <span class="text-primary">{{ livestockFormData.name }}</span>
                        stocked in on {{ livestockFormData.stock_in_date }}.
                      </p>
                    </b-alert>
                  </div>
                  <div class="mt-1">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Status"
                        rules="required"
                      >
                        <b-form-select
                          v-model="selectedStatus"
                          :options="livestockStatusOptions"
                          :state="errors.length > 0 ? false:null"
                        />
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="mt-1">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Quantity"
                        rules="required"
                      >
                        <b-form-input
                          v-model="managedQuantity"
                          placeholder="Quantity"
                          type="number"
                          :state="errors.length > 0 ? false:null"
                        />
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="mt-1">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Event Date"
                        rules="required"
                      >
                        <b-form-datepicker
                          v-model="event_date"
                          placeholder="Choose Event Date"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div class="mt-1">
                    <b-form-textarea
                      v-model="managedRemark"
                      placeholder="Enter Remark"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
        <b-modal
          v-model="openRegisterLivestockDetailsModal"
          title="Livestock Details"
          size="lg"
          ok-only
        >
          <b-row>
            <b-col sm="12">
              <div class="d-flex align-content-center justify-content-center">
                <b-alert
                  variant="info"
                  show
                  class="py-1 px-1 w-100"
                >
                  <p class="text-center">The quantity at acquisition was <b class="text-success">{{ formatNumber(livestockFormData.innitial_quantity) }}</b>.
                  </p>
                </b-alert>
              </div>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Name:</span><span class="text-info ml-1">{{ livestockFormData.name }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Category:</span><span class="text-info ml-1">{{ livestockFormData.category }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Type:</span><span class="text-info ml-1">{{ livestockFormData.type }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Quantity:</span><span class="text-info ml-1">{{ livestockFormData.quantity }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Stockin Date:</span><span class="text-info ml-1">{{ livestockFormData.stock_in_date }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Gender:</span><span class="text-info ml-1">{{ livestockFormData.gender }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Acquisition Channel:</span><span class="text-info ml-1">{{ livestockFormData.acquistion_type }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Natal Date:</span><span class="text-info ml-1">{{ livestockFormData.natal_date }}</span></p>
            </b-col>
            <b-col
              sm="12"
            >
              <p><span>Description:</span><span class="text-info ml-1">{{ livestockFormData.description }}</span></p>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          v-model="openRegisterLivestockModal"
          :title="isUpdate ? 'Update Livestock' : 'Register Livestock'"
          size="lg"
          :ok-title="isUpdate ? 'Update' : 'Register'"
          @ok.prevent="validationForm"
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Livestock"
                      rules="required"
                    >
                      <b-form-select
                        v-model="livestockItem"
                        :options="livestockOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <b-form-select
                        v-model="livestockType"
                        :options="livestockTypeOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Livestock Type Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="livestockFormData.category_type_identification"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Livestock Type Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Farm"
                      rules="required"
                    >
                      <b-form-input
                        v-model="livestockFormData.farm"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Farm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                <b-col
                  v-if="!isUpdate"
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Quantity"
                      rules="required"
                    >
                      <b-form-input
                        v-model="livestockFormData.quantity"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Quantity"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Livestock ID Number"
                      rules="required"
                    >
                      <b-form-input
                        v-model="livestockFormData.livestock_id_number"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Livestock ID Number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <b-form-select
                      v-model="livestockGender"
                      :options="livestockGenderOptions"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Stockin Channel"
                      rules="required"
                    >
                      <b-form-select
                        v-model="stockinChannel"
                        :options="stockinChannelOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Stockin Date"
                      rules="required"
                    >
                      <b-form-datepicker
                        v-model="livestockFormData.stock_in_date"
                        placeholder="Choose Stockin Date"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Natal Date"
                      rules="required"
                    >
                      <b-form-datepicker
                        v-model="livestockFormData.natal_date"
                        placeholder="Choose Natal Date"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="12"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Description"
                      rules="required"
                    >
                      <b-form-textarea
                        v-model="livestockFormData.description"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Description"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BBadge, BAlert, BFormSelect, BFormTextarea, BModal, BFormDatepicker, BForm, BPagination, BFormGroup, BFormInput, BDropdownItem, BDropdown,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import { supabase } from '@/libs/supabaseClient'
// eslint-disable-next-line import/no-cycle
import { formatNumber } from '@core/utils/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BBadge,
    BModal,
    BForm,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BFormTextarea,
    BFormDatepicker,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      managedQuantity: null,
      managedRemark: '',
      event_date: '',
      openRegisterLivestockModal: false,
      openRegisterLivestockDetailsModal: false,
      openManageLivestockModal: false,
      selectedStatus: null,
      livestockItem: null,
      livestockType: null,
      livestockGender: null,
      stockinChannel: null,
      isUpdate: false,
      dir: false,
      required,
      email,
      liveStockConfigData: [],
      livestockFormData: {
        live_stock_id: 0,
        gender: '',
        category: '',
        type: '',
        farm_id: 0,
        quantity: 0,
        acquistion_type: '',
        stock_in_date: '',
        natal_date: '',
        description: '',
        stock_in_date_timestamp: 0,
        name: '',
        innitial_quantity: 0,
      },
      pages: ['3', '5', '10'],
      livestockTypeOptions: [
        { value: null, text: 'Select Type', disabled: true },
        { value: 'Local', text: 'Local' },
        { value: 'Exotic', text: 'Exotic' },
      ],
      stockinChannelOptions: [
        { value: null, text: 'Select Aquisition Channel', disabled: true },
        { value: 'Domestic Birth', text: 'Domestic Birth' },
        { value: 'Donation', text: 'Donation' },
        { value: 'Bought', text: 'Bought' },
      ],
      livestockGenderOptions: [
        { value: null, text: 'Select Gender', disabled: true },
        { value: 'Male', text: 'Male' },
        { value: 'Female', text: 'Female' },
        { value: 'Uncertain', text: 'Uncertain' },
      ],
      livestockOptions: [
        { value: null, text: 'Select Livestock', disabled: true },
      ],
      livestockStatusOptions: [
        { value: null, text: 'Select Status', disabled: true },
        { value: 'Sold', text: 'Sold' },
        { value: 'Died', text: 'Died' },
        { value: 'Slaughtered for domestic consumption', text: 'Slaughtered for domestic consumption' },
        { value: 'Donated', text: 'Donated' },
      ],
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Category',
          field: 'category',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Qty',
          field: 'quantity',
        },
        {
          label: 'Stockin Date',
          field: 'stock_in_date',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      formatNumber,
      searchTerm: '',
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.handleGetLiveStock()
    this.handleGetConfigLiveStock()
  },
  methods: {
    resetLiveStockFormData() {
      this.livestockItem = null
      this.livestockFormData = {
        live_stock_id: 0,
        gender: '',
        category: '',
        type: '',
        farm_id: 0,
        quantity: 0,
        acquistion_type: '',
        stock_in_date: '',
        natal_date: '',
        description: '',
        stock_in_date_timestamp: 0,
        name: '',
        innitial_quantity: 0,
      }
    },
    handleOpenRegisterModal() {
      this.isUpdate = false
      this.openRegisterLivestockModal = true
    },
    handleOpenViewProduceModal(item) {
      this.openRegisterLivestockDetailsModal = true
      console.log('::debug view Item:', item)
      this.livestockFormData = item
    },
    handleOpenEditProduceModal(item) {
      this.resetLiveStockFormData()
      this.isUpdate = true
      this.openRegisterLivestockModal = true
      this.livestockFormData = item
      this.livestockItem = this.livestockFormData.live_stock_id
      this.livestockGender = this.livestockFormData.gender
      this.stockinChannel = this.livestockFormData.acquistion_type
    },
    handleOpenManageLivestockModal(item) {
      this.resetLiveStockFormData()
      this.managedQuantity = ''
      this.managedRemark = ''
      this.selectedStatus = null
      this.event_date = ''
      this.openManageLivestockModal = true
      this.livestockFormData = item
    },
    handleGetConfigLivestockData() {
      const selectedItem = this.liveStockConfigData.filter(item => item.id === this.livestockItem)
      return selectedItem
    },
    async handleUpdateLiveStock() {
      this.$Progress.start()
      const selectedLivestock = this.handleGetConfigLivestockData()
      this.livestockFormData.gender = this.livestockGender
      this.livestockFormData.live_stock_id = this.livestockItem
      this.livestockFormData.name = selectedLivestock[0].name
      this.livestockFormData.category = selectedLivestock[0].category
      this.livestockFormData.type = selectedLivestock[0].type
      this.livestockFormData.acquistion_type = this.stockinChannel
      // eslint-disable-next-line radix
      this.livestockFormData.quantity = parseInt(this.livestockFormData.quantity)
      this.livestockFormData.farm_id = 1
      const stockinTimesatmp = new Date(this.livestockFormData.stock_in_date).getTime()
      this.livestockFormData.stock_in_date_timestamp = stockinTimesatmp / 1000
      try {
        const { error } = await supabase
          .from('live_stock_and_poultry')
          .update({
            name: this.livestockFormData.name,
            category: this.livestockFormData.category,
            type: this.livestockFormData.type,
            stock_in_date: this.livestockFormData.stock_in_date,
            stock_in_date_timestamp: this.livestockFormData.stock_in_date_timestamp,
            gender: this.livestockFormData.gender,
            acquistion_type: this.livestockFormData.acquistion_type,
            description: this.livestockFormData.description,
            live_stock_id: this.livestockFormData.live_stock_id,
            natal_date: this.livestockFormData.natal_date,
            farm_id: this.livestockFormData.farm_id,
          })
          .eq('id', this.livestockFormData.id)
          .select()
        if (error) {
          this.$Progress.fail()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Update Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else {
          this.$Progress.finish()
          this.openRegisterLivestockModal = false
          this.isUpdate = false

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Update Successful',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Data posted successfully',
            },
          })
          this.handleGetLiveStock()
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error Occurred',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.resetLiveStockFormData()
      }
    },
    async handleRegisterLiveStock() {
      this.$Progress.start()
      const selectedLivestock = this.handleGetConfigLivestockData()
      this.livestockFormData.gender = this.livestockGender
      this.livestockFormData.live_stock_id = this.livestockItem
      this.livestockFormData.name = selectedLivestock[0].name
      this.livestockFormData.category = selectedLivestock[0].category
      this.livestockFormData.type = selectedLivestock[0].type
      this.livestockFormData.acquistion_type = this.stockinChannel
      // eslint-disable-next-line radix
      this.livestockFormData.quantity = parseInt(this.livestockFormData.quantity)
      this.livestockFormData.farm_id = 1
      const stockinTimesatmp = new Date(this.livestockFormData.stock_in_date).getTime()
      this.livestockFormData.stock_in_date_timestamp = stockinTimesatmp / 1000
      try {
        const { error } = await supabase
          .from('live_stock_and_poultry')
          .insert([
            this.livestockFormData,
          ])
          .select()
        if (error) {
          this.openRegisterLivestockModal = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Registration Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else {
          this.openRegisterLivestockModal = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Registration Successful',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Data posted successfully',
            },
          })
          this.handleGetLiveStock()
        }
      } catch (error) {
        this.$Progress.fail()
        this.openRegisterLivestockModal = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Registration Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.$Progress.finish()
        this.resetLiveStockFormData()
      }
    },
    async handleRegisterLiveStockInventory() {
      this.$Progress.start()
      try {
        const eventTimeStamp = new Date(this.event_date).getTime()
        // eslint-disable-next-line radix
        const qty = parseInt(this.managedQuantity)
        const { error } = await supabase
          .from('live_stock_and_poultry_inventory')
          .insert([
            {
              live_stock_poultry_id: this.livestockFormData.id,
              quantity: qty,
              remark: this.managedRemark,
              status: this.selectedStatus,
              event_date: this.event_date,
              event_date_timestamp: eventTimeStamp,
            },
          ])
          .select()

        const { liveStockError } = await supabase
          .from('live_stock_and_poultry')
          .update({
            quantity: (this.livestockFormData.quantity - qty),
          })
          .eq('id', this.livestockFormData.id)
          .select()
        if (error || liveStockError) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Registration Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else {
          this.openManageLivestockModal = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Registration Successful',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Data posted successfully',
            },
          })
          this.handleGetLiveStock()
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Registration Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.resetLiveStockFormData()
        this.$Progress.finish()
      }
    },
    async handleGetLiveStock() {
      this.$Progress.start()
      try {
        const { data: response, error } = await supabase
          .from('live_stock_and_poultry')
          .select(`
            *
          `).order('name', { ascending: true })
        if (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Fetch Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else if (response.length > 0) {
          console.log('::debug produce response:', response)
          this.rows = []
          this.rows = response
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Fetch Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.$Progress.finish()
      }
    },
    async handleGetConfigLiveStock() {
      this.$Progress.start()
      try {
        const { data: response, error } = await supabase
          .from('config_livestock')
          .select('*').order('name', { ascending: true })
        if (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Fetch Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else if (response.length > 0) {
          this.liveStockConfigData = response
          response.map(item => this.livestockOptions.push(
            {
              value: item.id,
              text: item.name,
            },
          ))
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Fetch Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.$Progress.finish()
      }
    },
    validateManageLiveStockForm() {
      this.$refs.manageLivestock.validate().then(success => {
        console.log('::debug manageLivestock')
        if (success) {
          this.handleRegisterLiveStockInventory()
        }
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          console.log('::Debug this.update:', this.isUpdate)
          if (this.isUpdate) {
            this.handleUpdateLiveStock()
          } else {
            this.handleRegisterLiveStock()
          }
        }
      })
    },

    isEmptyObject(obj) {
      return Object.keys(obj).length === 0
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
